import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, DialogProps } from "@mui/material";
import { LoaderButton } from "components/loader-button/LoaderButton";
import styles from "./ConfirmDialog.module.scss";

interface ConfirmDialogProps extends Partial<DialogProps> {
    text?: string,
    visible: boolean,
    onClose: () => any;
    onCancel?: () => any;
    onAccept?: () => any;
    title?: string;
    children?: React.ReactElement | React.ReactElement[],
    acceptLabel?: string,
    cancelLabel?: string,
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({text, onClose, onCancel, onAccept, visible, title, children, acceptLabel="Yes", cancelLabel="No", ...rest}: ConfirmDialogProps) => {
    return (
        <div>
        { visible && 
            <Dialog
                className={styles['dialog']}
                onClose={onClose}
                {...rest}
                open={visible}
            >
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent>
                    {text && <DialogContentText>{text}</DialogContentText>}
                    {children}
                </DialogContent>
                <DialogActions className={styles['dialog-actions']}>
                    <LoaderButton id="confirm-dialog-cancel" variant="secondary" onClick={() => onCancel ? onCancel() : onClose()}>{cancelLabel}</LoaderButton>
                    { onAccept && <LoaderButton id="confirm-dialog-accept" variant="primary-inverse" onClick={() => onAccept()}>{acceptLabel}</LoaderButton>}
                </DialogActions>
            </Dialog>
        }
        </div>
    );
};
